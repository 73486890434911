import React from 'react';
import '../../../../App.css';
import HeroSection from './HeroSection';

function DeleteStaff() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default DeleteStaff;
