import React from "react";
import "../../../Cards.css";
import { Card, Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/merchant/1.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Tingkatkan & Kembangkan <br /> Bisnis Anda
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Raih setiap peluang dan jangkau lebih banyak pembeli<br/>
                  bersama Yipy Merchant.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                Pemberitahuan Transaksi <br/>
                Secara <i>Real Time</i>
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Dapatkan pemberitahuan secara langsung saat <br/>
                  menerima transaksi pembelian.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/merchant/2.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/merchant/3.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                Laporan Hasil Penjualan<br/>
                Dengan Lengkap
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Pantau semua statistik penjualan dalam satu <br/>
                  genggaman aplikasi Yipy Merchant.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
