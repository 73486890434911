import React from 'react';
import '../../../App.css';
import UnderConstruction from './UnderConstruction';

function ComingSoon() {
  return (
    <>
      <UnderConstruction />
    </>
  );
}

export default ComingSoon;
