import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-download">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Belum punya aplikasi Yipy? Yuk, unduh sekarang juga.</h1>
      <p>
        <div className="row">
          <div className="col download">
              <a href="https://apps.apple.com/id/app/yipy/id1531666213?l=id" target="_blank">
                <Image fluid src="/images/homepage/ios.png" rounded height={44} />
              </a>
          </div>
          <div className="col download">
              <a href="https://play.google.com/store/apps/details?id=com.yipy.centratamagroup.resident" target="_blank">
                <Image fluid src="/images/homepage/android.png" rounded height={43} />
              </a>
          </div>
        </div>
      </p>
    </div>
  );
}

export default HeroSection;
