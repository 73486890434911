import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";
import "./staff.css"

function HeroSection() {

  function sendData() {
    var emailValue = document.getElementById('email').value;
    
    const url = "https://api.yipy.id/yipy-management/deletestaff";

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        email: emailValue,
      }),
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });

      const div1 = document.getElementsByClassName('content-send-email')[0];
      const div2 = document.getElementsByClassName('content-delete-account')[0];

      div1.style.display = 'none';
      div2.style.display = 'block';
  }
  
  return (
    <div className="container-body">
      <div class="left-section">
      </div>
      <div class="right-section">
        <div class="vertical-align-content">
          <div class="content">
            <table class="content-send-email">
              <tr>
                <td>
                  <p><h1>Now just a minute.</h1></p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Are you sure want to request delete account?
                  <br/>
                  If you're sure, enter your email address to confirmation
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><input typeof="email" placeholder="Email" name="email" class="email" id="email" /></p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><button typeof="button" className="button-email" onClick={sendData}>Send Email Confirmation</button></p>
                </td>
              </tr>
            </table>

            <table class="content-delete-account">
              <tr>
                <td>
                  <p><h1>Confirmation email has been sent!</h1></p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>We have send a link to your email to confirm your deletion account.
                  <br/>
                  please check your email inbox.</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p><b>Please note:</b> Did not received the email? Check your spam or junk folder and blocklist</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
