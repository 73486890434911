
import React from 'react';
import './Cards.css';
import CardItemV2 from './CardItemv2';

function Cards() {
  return (
    <div className='cards'>
      <h1>Mereka yang Happy Bareng <span className='font-red'>Yipy</span></h1>
      <div className='cards__container__v2'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItemV2
              src='images/homepage/testimoni/testi1.png'
              name='Arman R'
              status='Owner Bakery'
              testimoni='Yipy bisa diakses dimana
              saja, sehingga memudahkan
              penggunanya terutama untuk
              yang punya bisnis.Reportnya
              real time, mudah digunakan.'
              // path='/services'
            />
            <CardItemV2
              src='images/homepage/testimoni/testi2.png'
              name='Natasya I.'
              status='Pengelola SIP Apartment'
              testimoni='Yipy memudahkan kami selaku
              pihak BM dalam mengelola
              semua aspek manajemen. CS
              Yipy juga sangat responsif ketika
              kami ada masalah.'
              // path='/products'
            />
            <CardItemV2
              src='images/homepage/testimoni/testi3.png'
              name='Rudi Setiawan'
              status='Staff Technician'
              testimoni='Berkat Yipy saya jadi lebih
              mudah melaporkan hasil
              pekerjaan saya. Hasil laporan
              pekerjaan saya juga jadi lebih
              rapi.'
              // path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;