import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Beragam Solusi Hunian Dalam Satu Aplikasi</h1>
      <p>
      Platform digital yang diperuntukan Kepada seluruh pengelola dan
      penghuni apartemen ataupun perumahan. Sehingga menciptakan
      ekosistem digital guna memudahkan dalam memenuhi kebutuhan
      operasional dan kebutuhan sehari-hari kalian.
      </p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://forms.gle/Lj7ERajKGtMoHqzv7','_blank')
            }}
        >
          <b>DEMO GRATIS</b>
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
