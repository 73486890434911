import React from "react";
import "../../Cards.css";
import "../../../App.css";
import { Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container blogs">
       <span className="noinfo">Belum Ada Promo Yang Ditampilkan</span>
        {/* <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/1.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Cashback 30% Shopeepay Untuk Semua Pembayaran
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">1 - 31 October 2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/2.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      DISKON SERBA 17K STREET BOBA & ICHISAN SUSHI
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">
                      1 October - 31 November 2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/3.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      DISKON HINGGA 10 RIBU di PRIMA FRESHMART
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">
                      1 October - 31 December 2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/4.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      DISKON HINGGA 50RB BAYAR TAGIHAN PAKAI DEBIT BTN
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">1 - 31 October 2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/2.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      DISKON SERBA 17K STREET BOBA & ICHISAN SUSHI
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">
                      1 October - 31 November 2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/promo/image/3.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      DISKON HINGGA 10 RIBU di PRIMA FRESHMART
                    </div>
                    <div className="period-promo">Periode Promo</div>
                    <div className="date-promo">
                      1 October - 31 December 2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Content;
