import React from 'react';
import '../../../App.css';
import Footer from '../../Footer';
import Content from './Content';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';
import Form from './Form';

function Kontak() {
  return (
    <>
      <HeroSection />
      <Content />
      {/* <Form /> */}
      <MoreInfo />
      <Footer />
    </>
  );
}

export default Kontak;
