import React from "react";
import "../../Cards.css";
import "../../../App.css";
import { Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container blogs">
        <span className="noinfo">Belum Ada Informasi Yang Ditampilkan</span>
        {/* <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/1.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Cara mudah bikin laporan seputar kendala hunian
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">6 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/2.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Sekarang bisa bayar apapun di Yipy pakai Shopeepay
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">8 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/3.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Mau bayar tagihan bulanan? Simak caranya disini
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">10 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/1.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Cara mudah bikin laporan seputar kendala hunian
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">6 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/2.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Sekarang bisa bayar apapun di Yipy pakai Shopeepay
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">8 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/3.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Mau bayar tagihan bulanan? Simak caranya disini
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">10 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/1.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Cara mudah bikin laporan seputar kendala hunian
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">6 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/2.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Sekarang bisa bayar apapun di Yipy pakai Shopeepay
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">8 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div className="BlogContainer">
                <div className="row">
                  <div className="col">
                    <Image
                      fluid
                      src="/images/homepage/blog/image/3.png"
                      rounded
                      width={360}
                    />
                    <div className="title-blog">
                      Mau bayar tagihan bulanan? Simak caranya disini
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="date-blog">10 Oct 2022</div>
                      </div>
                      <div className="col">
                        <div className="detail-blog">
                          Selengkapnya{" "}
                          <i
                            class="fas fa-arrow-right"
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Content;
