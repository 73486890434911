import React from "react";
import "../../Cards.css";
// import CardItem from "./CardItem";
import { Card, Image } from "react-bootstrap";
import { Button } from "../../Button";
import { Link } from "react-router-dom";

function MoreInfo() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/panda.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Cari informasi lengkap lainnya
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text">
                    Jangan khawatir, kami selalu ada untuk melayani dan
                    menyelesaikan masalah kamu dengan baik.
                  </div>
                  <div className="hero-btns-normal">
                  <Link to="/coming-soon">
                      <Button
                        className="btn"
                        buttonStyle="btn--secondary--outline"
                        buttonSize="btn--small"
                        buttonWeight="bolder"
                      >
                        Pusat Bantuan
                      </Button>
                    </Link>
                    <Link to="/hubungi kami">
                      <Button
                        className="btn"
                        buttonStyle="btn--secondary--outline"
                        buttonSize="btn--small"
                        buttonWeight="bolder"
                      >
                        Hubungi Kami
                      </Button>
                    </Link>
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
