import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer-container">
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Panduan</h2>
            <Link to="/coming-soon">Pusat Bantuan</Link>
            <Link to="/hubungi kami">Hubungi Kami</Link>
            <Link to="/coming-soon">How To Use</Link>
          </div>
          <div class="footer-link-items">
            <h2>Perusahaan</h2>
            <Link to="/coming-soon">Tentang</Link>
            <Link to="/blog">Blog</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Gabung Mitra</h2>
            <Link to="/mitra building">Mitra Building</Link>
            <Link to="/mitra merchant">Mitra Merchant</Link>
          </div>
          <div class="footer-link-items">
            <h2>Layanan</h2>
            <Link to="/layanan resident">Yipy Resident</Link>
            <Link to="/layanan staff">Yipy Staff</Link>
            <Link to="/layanan merchant">Yipy Merchant</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Unduh Aplikasi</h2>
            <a href="https://apps.apple.com/id/app/yipy/id1531666213?l=id" target="_blank">
              <Image fluid src="/images/homepage/ios.png" rounded height={44} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.yipy.centratamagroup.resident" target="_blank">
              <Image fluid src="/images/homepage/android.png" rounded height={43} />
            </a>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              <Image fluid src="/images/yipy-logo-white.png" rounded width={80} />
            </Link>
          </div>
          <small class="website-rights">
            Copyright © 2022 Yipy - PT. Sentra Inovasi Prima
          </small>
          <div class="social-icons">
            <a
              class="social-icon-link facebook"
              href="https://www.facebook.com/Yipy.id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </a>
            <a
              class="social-icon-link instagram"
              href="https://www.instagram.com/yipy_id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </a>
            <a
              class="social-icon-link youtube"
              href="https://www.youtube.com/channel/UC0ITiqoA4hv5Xbcl5CpOEEg?view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </a>
            <a
              class="social-icon-link linkedin"
              href="https://www.linkedin.com/in/yipy-id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
