import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";
import "./staff.css"

function DeleteSuccess() {  
  return (
    <div className="container-body">
      <div class="left-section">
      </div>
      <div class="right-section">
        <div class="vertical-align-content">
          <div class="content">
            <table class="content-send-email">

              <tr>
                <td>
                    <img class="deleteresidentsuccess" src="https://yipy-assets.s3.ap-southeast-1.amazonaws.com/Web+Delete+Account/delete-success.png" />
                </td>
              </tr>

              <tr>
                <td>
                  <p><h1>Deleted Successful</h1></p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>We're sad with your Goodbye! But hopefully it's just a while.
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteSuccess;
