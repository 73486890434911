import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-layanan-merchant">
      <h1>Buka Toko Online Jadi Lebih Mudah dan Banyak Untungnya</h1>
    </div>
  );
}

export default HeroSection;
