import React, { useState } from "react";
import "../../../App.css";
import { Button } from "../../Button";
import "./UnderConstruction.css";
import { Link } from "react-router-dom";

function UnderConstruction() {
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  return (
    <div className="coming-soon">
      <div className="container-box">
        <div className="outer-box">
          <div className="box">
            <h1>We're Coming Soon</h1>
          </div>
        </div>

        <div className="content">
          <p>
            Halaman ini sedang dalam pengerjaan untuk menjadi lebih baik.
          </p>
          <p>
            Jangan khawatir, kamu tetap bisa menjelajahi menu lainnya ya...
          </p>
          <div className="coming-soon-btns">
          <Link
                to="/"
                className="nav-links"
                onClick={closeMobileMenu}
              >
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
            >
              Back To Home
            </Button>
            </Link>
            <div class="social-icons">
            <a
              class="social-icon-link facebook"
              href="https://www.facebook.com/Yipy.id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </a>
            <a
              class="social-icon-link instagram"
              href="https://www.instagram.com/yipy_id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </a>
            <a
              class="social-icon-link youtube"
              href="https://www.youtube.com/channel/UC0ITiqoA4hv5Xbcl5CpOEEg?view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </a>
            <a
              class="social-icon-link linkedin"
              href="https://www.linkedin.com/in/yipy-id/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </a>
          </div>
          </div>
          
        </div>
      </div>
      
    </div>
  );
}

export default UnderConstruction;
