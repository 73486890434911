import React from 'react';
import '../../../../App.css';
import HeroSection from './HeroSection';

function DeleteResident() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default DeleteResident;
