import React from "react";
import "../../../Cards.css";
import { Card, Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/1.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Absensi Digital
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Pemantauan kehadiran staff dapat dilakukan secara<br/>
                  cepat dan real time karena tidak perlu melalui proses<br/>
                  absen manual.<br/>
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                Notifikasi Pekerjaan<br/>
                Secara Langsung
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Staff yang sedang <i>On Shift</i> akan menerima<br/>
                  notifikasi secara langsung ketika mendapatkan<br/>
                  tugas.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/notifikasi-task.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/pantau-laporan task.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                Pantau Laporan Tugas<br/>
                Dengan Mudah
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                  Yipy menyediakan dashboard yang dapat<br/>
                  mempermudah staff dalam memantau setiap<br/>
                  proses saat pelaksaan tugas.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
