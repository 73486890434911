import React from "react";
import "../../../Cards.css";
import { Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items merchant">
              <h1 className="card-title-mitra">Layanan Operasional</h1>
              <div className="card-title-border"></div>
              <div>
                <Image
                  fluid
                  src="/images/homepage/mitra/merchant/1.png"
                  rounded
                />
              </div>
            </div>
            <div class="card-link-items merchant">
              <h1 className="card-title-blank"> </h1>
              <div className="more-info-title-mitra">Laporan Penjualan</div>
              <div>
                <div className="more-info-text-content-mitra">
                  Memudahkan kamu dalam melihat dan menghitung jumlah penjualan
                  tokomu.
                </div>
              </div>
              <div className="more-info-title-mitra">Mudah Atur Produk</div>
              <div>
                <div className="more-info-text-content-mitra">
                  Kamu bisa dengan mudah atur semuanya mulai dari stok hingga
                  detail produkmu.
                </div>
              </div>
              <div className="more-info-title-mitra">
                Atur Jadwal Operasional Toko
              </div>
              <div>
                <div className="more-info-text-content-mitra">
                  Atur jadwal operasional tokomu biar bisa maksimalin
                  produktivitas penjualan.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
