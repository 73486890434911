import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-mitra-building">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Kelola sistem hunian jadi lebih mudah</h1>
    </div>
  );
}

export default HeroSection;
