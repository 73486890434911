import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-layanan-resident">
      <h1>Jadi Lebih Mudah <br /> Dengan Aplikasi Yipy</h1>
      <p>
      Kamu bisa menikmati setiap fitur di Yipy untuk penuhi semua <br/>
      kebutuhan hunianmu.
      </p>
    </div>
  );
}

export default HeroSection;
